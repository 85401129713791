import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import homePage from './HomePage/homePage'
import './App.scss'
import { captchaSettings } from 'reactjs-captcha'
import { useLocation } from 'react-router'

captchaSettings.set({
	captchaEndpoint: '/bdc4-simple-api-react-captcha-example/botdetectcaptcha',
})

export const App = () => {
	const location = useLocation()
	const collegeId = location.pathname.split('/')[1]

	return (
		<div className='App'>
			<Switch>
				<Redirect exact from='/' to='/home' />
				<Route path={`/home`} component={homePage} />
			</Switch>
		</div>
	)
}
