import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { booleanOptions, maritalCircumstances } from '@Root/configs'

export const UserCircumstances = ({ onChange, data, isMobile }) => {
	const {
		marital_status,
		name_of_your_spouse,
		surname_of_your_spouse,
		names_and_ages_of_your_children,
		additional_needs,
		additional_needs_description,
		learning_difficulty,
		learning_difficulty_description,
		professional_spld,
		about_professional_spld,
	} = data
	return (
		<div className={styles.section}>
			<SectionTitle>7. Your circumstances</SectionTitle>
			<div className={styles.fields}>
				<Field
					label={'Marital status'}
					options={maritalCircumstances}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={marital_status}
					onChange={onChange('marital_status')}
					isInline
					fieldClass={'single'}
				/>
				<div className={styles.row}>
					<Field
						label={'Spouse Name'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '150px' }}
						value={name_of_your_spouse}
						onChange={onChange('name_of_your_spouse')}
						isInline
						fieldClass={'main'}
					/>
					<Field
						label={'Spouse Surname'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '150px' }}
						value={surname_of_your_spouse}
						onChange={onChange('surname_of_your_spouse')}
						isInline
						fieldClass={'main'}
					/>
				</div>
				<Field
					label={'Names and ages of your children'}
					field={'textField'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '257px' }}
					value={names_and_ages_of_your_children}
					onChange={onChange('names_and_ages_of_your_children')}
					isInline
					fieldClass={'long'}
				/>
				<Field
					label={'Do you have any additional needs that it would be helpful for us to be aware of(disability, dietary requirements, etc)?'}
					options={booleanOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={additional_needs ? 1 : 2}
					onChange={(value) => onChange('additional_needs')(value === 1)}
					isInline
					fieldClass={'single'}
				/>{' '}
				<Field
					label={'If yes, please tell us more about your needs'}
					field={'textArea'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', height: '90px', width: isMobile ? 'calc(100% - 20px)' : '96%' }}
					value={additional_needs_description}
					onChange={onChange('additional_needs_description')}
					isInline
					fieldClass={'single'}
				/>
				<Field
					label={'Do you have a specific learning difficulty, e.g. dyslexia?'}
					options={booleanOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={learning_difficulty ? 1 : 2}
					onChange={(value) => onChange('learning_difficulty')(value === 1)}
					isInline
					fieldClass={'single'}
				/>{' '}
				<Field
					label={'If yes, please tell us more about how we can accommodate you'}
					field={'textArea'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '96%', height: '90px' }}
					value={learning_difficulty_description}
					onChange={onChange('learning_difficulty_description')}
					isInline
					fieldClass={'single'}
				/>
				<Field
					label={'if the candidate has had a professional SPLD diagnosis within the last 5 years'}
					options={booleanOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={professional_spld ? 1 : 2}
					onChange={(value) => onChange('professional_spld')(value === 1)}
					isInline
					fieldClass={'single'}
				/>{' '}
				<Field
					label={'Please tell us more about professional SPLD'}
					field={'textArea'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '96%', height: '90px' }}
					value={about_professional_spld}
					onChange={onChange('about_professional_spld')}
					isInline
					fieldClass={'single'}
				/>
			</div>
		</div>
	)
}
