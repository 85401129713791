import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'

export const InputError = ({ children, error, style }) => {
	return (
		<div style={style} className={classes.InputError}>
			{error ? <div className={classes.wrapper}>{children}</div> : children}
			{error && <div className={classes.error}>{error}*</div>}
		</div>
	)
}

InputError.propTypes = {
	children: PropTypes.element,
}
